import { FC } from 'react';
import camelCase from 'lodash/camelCase';

import * as Components from 'Components';

const DynamicComponent: FC<any> = ({ component }) => {
  const components = {};

  for (const [key, value] of Object.entries(Components)) {
    components[camelCase(key)] = value;
  }

  const Dynamic = components[component.component];

  const isSSR = typeof window === 'undefined';

  console.log('Dynamic', { isSSR, Dynamic });

  if (!Dynamic) return null;

  return (
    <div key={component?._uid} data-component={component.component}>
      <Dynamic data={component} />
    </div>
  );
};

export { DynamicComponent };
