import Head from 'next/head';
import { FC } from 'react';
import identity from 'lodash/identity';

import { BannerComponent, ContactComponent, PromosComponent, PriceListComponent } from 'Components';
import { Storyblok, DynamicComponent } from 'Utils';

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getStaticProps({ preview = false, params }) {
  console.log({ params });
  // home is the default slug for the homepage in Storyblok
  let slug = params?.page?.join('/') ?? 'home';

  console.log({ slug });
  // load the published content outside of the preview mode
  let sbParams = {
    version: 'draft', // or 'published'
    // resolve_links: 'story',
    // resolve_relations: 'promoItem.page',
    resolve_relations: ['promosComponent.pages', 'linkList.links'],
  };

  if (preview) {
    // load the draft version inside of the preview mode
    sbParams.version = 'draft';
    // @ts-expect-error
    sbParams.cv = Date.now();
  }

  let res = await Storyblok.get(`cdn/stories/${slug}`, sbParams);
  let linkRes = await Storyblok.get(`cdn/stories`, { ...sbParams, content_type: 'linkList' });

  const links = linkRes.data.stories.map(identity);

  const { data } = res;
  console.log({ content: data?.story?.content });
  console.log({ links });

  return {
    props: {
      story: data ? data.story : null,
      links,
      preview,
    },
    revalidate: 1, // revalidate every second
  };
}

const Page: FC = ({ story, links }) => {
  // const story = useAtomValue(storyAtom);

  const sections = story?.content?.sections ?? [];
  const {
    backgroundImages = [],
    title,
    subtitle,
    contactTitle,
    contactText,
    isArticle,
  } = story?.content ?? {};

  const isSSR = typeof window === 'undefined';
  console.log('Page', { isSSR }, story, { links });

  return (
    <>
      <Head>
        <title>{story?.content?.title} | Photo Natives</title>
      </Head>

      <BannerComponent data={{ title, _uid: story.uuid }} />

      <div className="sections" key={story?.uuid} data-is-article={isArticle}>
        {sections.map((section) => (
          <DynamicComponent component={section} key={section?._uid} />
        ))}
      </div>

      <ContactComponent title={contactTitle} text={contactText} />
    </>
  );
};

Page.displayName = 'Page';

export default Page;
